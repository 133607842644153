var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('vuexy-logo'),_c('h2',{staticClass:"brand-text text-primary"},[_vm._v("eWork")])],1),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Register V2"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"\n            font-weight-bold\n            mb-1\n            pt-1\n            pb-1\n            alert alert-primary\n            text-center\n          ",attrs:{"title-tag":"h2"}},[_vm._v(" INSCRIPTION ")]),_c('b-overlay',{attrs:{"show":_vm.loading,"rounded":"sm"}},[(_vm.errors.message)?_c('div',{staticClass:"alert alert-danger p-1"},[_c('span',{staticClass:"text-danger text-sm",domProps:{"textContent":_vm._s(_vm.errors.message)}}),_c('br'),(_vm.errors.errors)?_c('span',[_vm._v("- "+_vm._s(_vm.errors.errors.password[0])+" ")]):_vm._e()]):_vm._e(),_c('validation-observer',{ref:"registerForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-register-form mt-2"},[_c('b-form-group',{attrs:{"label":"Nom","label-for":"register-firstname"}},[_c('validation-provider',{attrs:{"name":"firstname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-firstname","name":"register-firstname","state":errors.length > 0 ? false : null,"placeholder":"Johnson"},model:{value:(_vm.user.firstname),callback:function ($$v) {_vm.$set(_vm.user, "firstname", $$v)},expression:"user.firstname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Prénom","label-for":"register-lastname"}},[_c('validation-provider',{attrs:{"name":"lastname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-lastname","name":"register-lastname","state":errors.length > 0 ? false : null,"placeholder":"Robert"},model:{value:(_vm.user.lastname),callback:function ($$v) {_vm.$set(_vm.user, "lastname", $$v)},expression:"user.lastname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"register-email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","name":"register-email","state":errors.length > 0 ? false : null,"placeholder":"john@example.com"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"register-password","label":"Mot de passe"}},[_c('validation-provider',{attrs:{"name":"password","rules":"required|password|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"password","placeholder":"············"},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"confirm-password","label":"Confirmer le mot de passe"}},[_c('validation-provider',{attrs:{"name":"password_confirmation","rules":"confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"confirm-password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"password_confirmation","placeholder":"············"},model:{value:(_vm.user.confirmPassword),callback:function ($$v) {_vm.$set(_vm.user, "confirmPassword", $$v)},expression:"user.confirmPassword"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit","disabled":invalid},on:{"click":function($event){$event.preventDefault();return _vm.adminRegister($event)}}},[_vm._v(" Valider ")])],1)]}}])}),_c('div',{staticClass:"my-2 "},[_c('div',{},[_c('p',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("Vous avez déjà un compte ?")]),_c('b-link',{attrs:{"to":{ name: 'login' }}},[_c('span',[_vm._v(" Connectez-vous ici")])])],1)])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }