<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />

        <h2 class="brand-text text-primary">eWork</h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Register V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title
            title-tag="h2"
            class="
              font-weight-bold
              mb-1
              pt-1
              pb-1
              alert alert-primary
              text-center
            "
          >
            INSCRIPTION
          </b-card-title>
          <b-overlay :show="loading" rounded="sm">
            <div class="alert alert-danger p-1" v-if="errors.message">
              <span class="text-danger text-sm" v-text="errors.message"></span
              ><br />
              <span v-if="errors.errors"
                >- {{ errors.errors.password[0] }}
              </span>
            </div>
            <!-- form -->
            <validation-observer ref="registerForm" #default="{ invalid }">
              <b-form class="auth-register-form mt-2">
                <!-- username -->
                <b-form-group label="Nom" label-for="register-firstname">
                  <validation-provider
                    #default="{ errors }"
                    name="firstname"
                    rules="required"
                  >
                    <b-form-input
                      id="register-firstname"
                      v-model="user.firstname"
                      name="register-firstname"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Johnson"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group label="Prénom" label-for="register-lastname">
                  <validation-provider
                    #default="{ errors }"
                    name="lastname"
                    rules="required"
                  >
                    <b-form-input
                      id="register-lastname"
                      v-model="user.lastname"
                      name="register-lastname"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Robert"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- email -->
                <b-form-group label="Email" label-for="register-email">
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="register-email"
                      v-model="user.email"
                      name="register-email"
                      :state="errors.length > 0 ? false : null"
                      placeholder="john@example.com"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- password -->
                <b-form-group
                  label-for="register-password"
                  label="Mot de passe"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="password"
                    rules="required|password|min:8"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="register-password"
                        v-model="user.password"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        :state="errors.length > 0 ? false : null"
                        name="password"
                        placeholder="············"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  label-for="confirm-password"
                  label="Confirmer le mot de passe"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="password_confirmation"
                    rules="confirmed:password"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="confirm-password"
                        v-model="user.confirmPassword"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        :state="errors.length > 0 ? false : null"
                        name="password_confirmation"
                        placeholder="············"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-button
                  variant="primary"
                  block
                  type="submit"
                  :disabled="invalid"
                  @click.prevent="adminRegister"
                >
                  Valider
                </b-button>
              </b-form>
            </validation-observer>

            <!-- divider -->
            <div class="my-2 ">
              <div class="">
                <p class="text-center mt-2">
                  <span>Vous avez déjà un compte ?</span>
                  <b-link :to="{ name: 'login' }">
                    <span> Connectez-vous ici</span>
                  </b-link>
                </p>
              </div>
            </div>
          </b-overlay>
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import kyInput from "@/@core/layouts/components/ky-input.vue";
import {
  ValidationProvider,
  ValidationObserver,
  Validator,
} from "vee-validate";
import VuexyLogo from "@/@core/layouts/components/logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import dict from "../../customValidationErrorMessage";
import { mapState, mapActions } from "vuex";

//Validator.localize('en', dict);

export default {
  components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
    kyInput,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      user: {},
      loading: false,
      errorMessage: "",
      popover_disabled: true,
      haveLoader: false,
      firstname: "",
      lastname: "",
      // telephone: "",
      address: "",
      status: "",
      username: "",
      email: "",
      password: "",
      confirmPassword: "",
      confirm_password_error: null,
      sideImg: require("@/assets/images/pages/register-v2.svg"),
      // validation
      required,
      error: false,
      errors: {},
      email,
    };
  },
  computed: {
    ...mapState("auth", ["roles"]),

    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/register-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    async adminRegister() {
      const valid = await this.$refs.registerForm.validate();
      if (!valid) return;
      this.loading = true;
      this.registerAdmin(this.$formData(this.user))
        .then((r) => {
          this.loading = false;
          localStorage.setItem("email", this.user.email);
          this.$router.replace({
            name: "account-validate",
          });
        })
        .catch((error) => {
          this.loading = false;
          this.errorMessage = error;
          this.errors = error.response.data;
        });
    },
    checkPassword() {
      this.user.password !== this.user.confirmPassword
        ? (this.confirm_password_error =
            "Les deux mots de passe ne sont pas identiques")
        : (this.confirm_password_error = null);
    },

    ...mapActions("auth", ["getRoles", "registerAdmin"]),
  },

  mounted() {},
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>


